<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'PackingProfile' }"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>
    <packing-profile-form @onSubmit="onSubmit" />
  </div>
</template>

<script>
import { PackingProfile } from "../../common/api/api.service";
import PackingProfileForm from "../../components/packingProfile/PackingProfileForm.vue";
import { TOOLBAR_TITLE } from "../../store/actions.types";
export default {
  name: "PackingProfileCreate",
  components: {
    PackingProfileForm,
  },
  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "packing_profile_create");
  },

  methods: {
    onSubmit(form) {
      PackingProfile.post(form).then(() => {
        this.$router.push({ name: "PackingProfile" });
      });
    },
  },
};
</script>
